import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {providerList,locList,proLocSave,proLocList,proLocSaveRes} from 'Redux/Registration/reg.actions'
import PropTypes from 'prop-types'
import toast from 'react-hot-toast'
import CustomButton from 'components/CustomButton/CustomButton';
import Dropdown from 'components/Dropdown';
import TimePicker from 'components/TimePicker';
import SwitchButton from 'components/Switch'
import { useForm } from 'Hooks/validator';
import './style.css';

const days = [
	{ value: 1, name: 'Monday' },
	{ value: 2, name: 'Tuesday' },
	{ value: 3, name: 'Wednesday' },
	{ value: 4, name: 'Thursday' },
	{ value: 5, name: 'Friday' },
];

function ProviderLocationForm(props) {
	const defaultValues = {
		userId: 0,
		clinicId: localStorage.getItem('cid'),
		firstName: '',
		lastName: '',
		middleName: '',
		roleId: 2,
		phone: '',
		email: '',
		userName: '',
		password: '',
		active: 1,
		isAdministratorAccess: false,
	};
	const customErrorAttribute = {
		className: 'has-error',
		'another-attr': 'look-at-me',
		error: true,
		helperText: 'This field is required',
	};
	const { values, setValues, isValid, useInput, setIsResetform } = useForm(
		defaultValues,
		customErrorAttribute
	);
	function getDateWithTime(hr, min, sec) {
		let date = new Date()
		date.setHours(hr, min, sec)
		return date
	  }
	const [providers, setProviders] = useState([]);
	const [provider, setProvider] = useState('');
	const [locations, setLocations] = useState([]);
	const [location, setLocation] = useState(null);
	const [edit,setEdit]=useState(false)
	const [shift1, setShift1] = useState([
		{
		providerLocationId: 0,
		locationId:'',
		clinicId: localStorage.getItem('cid'),
		providerId: '',
		dayOfWeek: 1,
		startTime: getDateWithTime(8, 0, 0),
		endTime: getDateWithTime(12, 0, 0),
		shift: 1,
		active: 1,
	  },
	  {
		providerLocationId: 0,
		locationId:'',
		clinicId: localStorage.getItem('cid'),
		providerId: '',
		dayOfWeek: 2,
		startTime: getDateWithTime(8, 0, 0),
		endTime: getDateWithTime(12, 0, 0),
		shift: 1,
		active: 1,
	  },
	  {
		providerLocationId: 0,
		locationId:'',
		clinicId: localStorage.getItem('cid'),
		providerId: '',
		dayOfWeek: 3,
		startTime: getDateWithTime(8, 0, 0),
		endTime: getDateWithTime(12, 0, 0),
		shift: 1,
		active: 1,
	  },
	  {
		providerLocationId: 0,
		locationId:'',
		clinicId: localStorage.getItem('cid'),
		providerId: '',
		dayOfWeek: 4,
		startTime: getDateWithTime(8, 0, 0),
		endTime: getDateWithTime(12, 0, 0),
		shift: 1,
		active: 1,
	  },
	  {
		providerLocationId: 0,
		locationId:'',
		clinicId: localStorage.getItem('cid'),
		providerId: '',
		dayOfWeek: 5,
		startTime: getDateWithTime(8, 0, 0),
		endTime: getDateWithTime(12, 0, 0),
		shift: 1,
		active: 1,
	  },
	]);
	const [shift2, setShift2] = useState([
		{
			providerLocationId: 0,
			locationId: '',
			clinicId: localStorage.getItem('cid'),
			providerId: '',
			dayOfWeek: 1,
			startTime: getDateWithTime(13, 0, 0),
			endTime: getDateWithTime(17, 0, 0),
			shift: 2,
			active: 1,
		  },
		  {
			providerLocationId: 0,
			locationId: '',
			clinicId: localStorage.getItem('cid'),
			providerId: '',
			dayOfWeek: 2,
			startTime: getDateWithTime(13, 0, 0),
			endTime: getDateWithTime(17, 0, 0),
			shift: 2,
			active: 1,
		  },
		  {
			providerLocationId: 0,
			locationId: '',
			clinicId: localStorage.getItem('cid'),
			providerId: '',
			dayOfWeek: 3,
			startTime: getDateWithTime(13, 0, 0),
			endTime: getDateWithTime(17, 0, 0),
			shift: 2,
			active: 1,
		  },
		  {
			providerLocationId: 0,
			locationId: '',
			clinicId: localStorage.getItem('cid'),
			providerId: '',
			dayOfWeek: 4,
			startTime: getDateWithTime(13, 0, 0),
			endTime: getDateWithTime(17, 0, 0),
			shift: 2,
			active: 1,
		  },
		  {
			providerLocationId: 0,
			locationId: '',
			clinicId: localStorage.getItem('cid'),
			providerId: '',
			dayOfWeek: 5,
			startTime: getDateWithTime(13, 0, 0),
			endTime: getDateWithTime(17, 0, 0),
			shift: 2,
			active: 1,
		  },
	]);

	useEffect(()=>{
		props.providerList()
		props.locList()
	},[])

	useEffect(()=>{
		if(props.regData.providerList&&props.regData.providerList.response){
		 const data = props.regData.providerList.response.data
		 if(data&&data.results.length>0){
			let provs = [];
		if(localStorage.getItem('provArray')){
		  let a =JSON.parse(localStorage.getItem('provArray'))
          data.results.forEach(x=>{
			if(a.includes(x.id)){

			}else{
				provs.push(x)	
			}
		  })
		  let p = []
		  p =  provs.map((x,i)=>{
			 if(i===0){
				 setProvider(x.id)
				 let newShift1=shift1.map((y)=>{
					 return{
						 ...y,
						 providerId:y.providerId===''?x.id:y.providerId
					 }
				 })
				 setShift1(newShift1)
				 let newShift2=shift2.map((y)=>{
					 return{
						 ...y,
						 providerId:y.providerId===''?x.id:y.providerId
					 }
				 })
				 setShift2(newShift2)
			 }
			 return({
				 value:x.id,
				 name:x.firstName+' '+x.lastName+','+x.credential
			 })
		 })
		  setProviders(p)	
     }else{
		provs=data.results
		let p = []
		p =  provs.map((x,i)=>{
		   if(i===0){
			   setProvider(x.id)
			   let newShift1=shift1.map((y)=>{
				   return{
					   ...y,
					   providerId:y.providerId===''?x.id:y.providerId
				   }
			   })
			   setShift1(newShift1)
			   let newShift2=shift2.map((y)=>{
				   return{
					   ...y,
					   providerId:y.providerId===''?x.id:y.providerId
				   }
			   })
			   setShift2(newShift2)
		   }
		   return({
			   value:x.id,
			   name:x.firstName+' '+x.lastName+','+x.credential
		   })
	   })
		setProviders(p)	
	 }
		 }
		}
	  },[props.regData.providerList])

	  useEffect(()=>{
		if(props.regData.locList&&props.regData.locList.response){
		 const data = props.regData.locList.response.data
		 if(data&&data.results.length>0){
			let p = []
		 p =  data.results.map((x,i)=>{
			if(i===0){
				let newShift1=shift1.map((y)=>{
					return{
						...y,
						locationId:y.locationId===''?x.id:y.locationId
					}
				})
				setShift1(newShift1)
				let newShift2=shift2.map((y)=>{
					return{
						...y,
						locationId:y.locationId===''?x.id:y.locationId
					}
				})
				setShift2(newShift2)
			}
			return({
				value:x.id,
				name:x.locationName
			})
		})
		setLocations(p)
		 }
		}
	  },[props.regData.locList])

	  
  function getDateWithTime(hr, min, sec) {
    let date = new Date()
    date.setHours(hr, min, sec)
    return date
  }

	  function convertTime(time) {
		const hours = time.getHours()
		let mins = time.getMinutes()
		if (mins < 10) {
		  mins = '0' + mins
		}
		const newTime = `${hours}:${mins}`
		return newTime
	  }
	  const getDatevalue = (d) => {
		let hr = d.split(':')[0]
		let min =d.split(':')[1]
		let sec = 0;
		let date = new Date()
		date.setHours(parseInt(hr), parseInt(min), sec)
		return date
	  }


	  const handleProviderChange = (e) =>{
	    let newShift1=shift1.map((x)=>{
			return{
				...x,
				providerId:e.target.value
			}
		})
        setShift1(newShift1)
		let newShift2=shift2.map((x)=>{
			return{
				...x,
				providerId:e.target.value
			}
		})
		setShift2(newShift2)
		setProvider(e.target.value)
	  }



	const handleSwitchButton = (x,i,e)=>{
        if(x.shift===1){
		let newArray = shift1
		newArray[i].active=e.target.value===true?1:0;
		setShift1( [...newArray]);
	    }
	    if(x.shift===2){
			let newArray = shift2
			newArray[i].active=e.target.value===true?1:0;
			setShift2( [...newArray]);
	    }
	  }

	  const handleLocationChange =(x,i,e)=>{
		if(x.shift===1){
		  let shift1Array = shift1
		  shift1Array[i].locationId=e.target.value;
		  setShift1( [...shift1Array]);
		   }
		  if(x.shift===2){
			let shift2Array = shift2
			shift2Array[i].locationId=e.target.value;
			setShift2( [...shift2Array]);
		  }
	  }

	  const handleStartTime = (x,i,e)=>{
		// alert(convertTime(e.target.value))
		if(x.shift===1){
			let newArray = shift1
			newArray[i].startTime=e.target.value;
			setShift1( [...newArray]);
			 }
			if(x.shift===2){
			  let newArray = shift2
			  newArray[i].startTime=e.target.value;
			  setShift2( [...newArray]);
			}
	 }

	 const handleEndTime = (x,i,e) =>{
		if(x.shift===1){
			let newArray = shift1
			newArray[i].endTime=e.target.value;
			setShift1( [...newArray]);
			 }
			if(x.shift===2){
			  let newArray = shift2
			  newArray[i].endTime=e.target.value;
			  setShift2( [...newArray]);
			}
	 }

	 const handleSubmit = (e)=>{
		let array1 = []
		e.preventDefault()
        shift1.forEach((x)=>{
		array1.push({
			...x,
			startTime:convertTime(x.startTime),
			endTime:convertTime(x.endTime)
		})
	 })
	 shift2.forEach((x)=>{
		array1.push({
			...x,
			startTime:convertTime(x.startTime),
			endTime:convertTime(x.endTime)
		})
	 })
     props.proLocSave(array1)
	 }

	 useEffect(()=>{
		if(props.regData.proLocationResponse&&props.regData.proLocationResponse.response){
			const response = props.regData.proLocationResponse.response
			if(response.responseCode===0){
			  props.handleClose()
			  toast.success('Provider reschedule added successfully')
			  if(localStorage.getItem('provArray')){
				let a = JSON.parse(localStorage.getItem('provArray'));
				a.push(shift1[0].providerId)
				localStorage.setItem('provArray',JSON.stringify(a))
			 }else{
				let a = []
				a.push(shift1[0].providerId)
				localStorage.setItem('provArray',JSON.stringify(a))
			 }
			  props.proLocSaveRes(null)
			  props.proLocList()
			}
		  }
	 },[props.regData.proLocationResponse])


	 useEffect(()=>{
		let s1=[]
		let s2=[]
     if(props.editValue){
		setEdit(true)
		props.editValue.forEach(x=>{
			if(x.shift===1){
				s1.push({
					active:x.active,
                    clinicId:x.clinicId,
					dayOfWeek:x.dayOfWeek,
					endTime:getDatevalue(x.endTime),
					locationId:x.locationId,
					providerId:x.providerId,
					providerLocationId:x.providerLocationId,
					shift:x.shift,
					startTime:getDatevalue(x.startTime),
				  })	
			}else if(x.shift===2){
				s2.push({
					active:x.active,
                    clinicId:x.clinicId,
					dayOfWeek:x.dayOfWeek,
					endTime:getDatevalue(x.endTime),
					locationId:x.locationId,
					providerId:x.providerId,
					providerLocationId:x.providerLocationId,
					shift:x.shift,
					startTime:getDatevalue(x.startTime),
				  })
			}
		})

	 setShift1(s1)
	 setShift2(s2)
	 }
},[props.editValue])
	return (
		<div>
			<form

			  onSubmit={handleSubmit}
			>
				<div className='first_row'>
					{edit===false&&<div className='row_item'>
						<Dropdown
							id={'rolex'}
							options={providers}
							label='Provider Name'
							value={provider}
						    onChange={(e) => handleProviderChange(e)}
						/>
					</div>}
				</div>

				<div className='subheading'>
					<div className='row_item'>
						<div className='form_subheading'>Days</div>
					</div>
					<div className='row_item'>
						<div className='form_subheading'>Shift 1</div>
					</div>
					<div className='row_item'>
						<div className='form_subheading'>Shift 2</div>
					</div>
				</div>
				<div className="column_container">
          <div className="column">
            {shift1.map((x, i) => (
              <div className="row1" key={i}>
                <div className="row_item_day">
                  <div className="weekday">
                    {days.find((y)=>y.value===x.dayOfWeek).name}
                  </div>
                </div>
                <div className="row_item_switch">
                  <SwitchButton
                    onChange={(e) => handleSwitchButton(x,i,e)}
                    value={x.active===1?true:false}
                  />
                </div>
                <div className="row_item">
                  <Dropdown
                    id={'role'}
                    options={locations}
                    label="Location"
                    value={x.locationId}
                    onChange={(e) => handleLocationChange(x,i,e)}
                  />
                </div>
                <div className="row_item">
                  <TimePicker
                    label="Start Time"
                    value={x.startTime}
                    onChange={(e) => handleStartTime(x,i,e)}
                  />
                </div>
                <div className="row_item">
                  <TimePicker
                    label="End Time"
                    value={x.endTime}
                    onChange={(e) => handleEndTime(x,i,e)}
                  />
                </div>
                <div className="row_item_dash">
                  <div>-</div>
                </div>
              </div>
            ))}
          </div>

          <div className="column">
            {shift2.map((x, i) => (
              <div className="row2" key={i}>
                <div className="row_item_switch">
                  <SwitchButton
                    onChange={(e) => handleSwitchButton(x,i,e)}
                    value={x.active===1?true:false}
                  />
                </div>
                <div className="row_item">
                  <Dropdown
                    id={'role'}
                    options={locations}
                    label="Location"
                    value={x.locationId}
					onChange={(e) => handleLocationChange(x,i,e)}
                  />
                </div>
                <div className="row_item">
                  <TimePicker
                    label="Start Time"
                    value={x.startTime}
					onChange={(e) => handleStartTime(x,i,e)}
                  />
                </div>
                <div className="row_item">
                  <TimePicker
                    label="End Time"
                    value={x.endTime}
                    onChange={(e) => handleEndTime(x,i,e)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

				<div className='form__btnWrapper'>
					<CustomButton
						name='Cancel'
						onClick={()=>props.handleClose()}
					/>
					<CustomButton
						name='Submit'
						type='submit'
					/>
				</div>
			</form>
		</div>
	);
}

const mapStateToProps = (state) => ({
	regData: state.reg,
  })
  
  const mapDispatchToProps = (dispatch) => ({
	providerList:() => dispatch(providerList()),
	locList:() => dispatch(locList()),
	proLocList:() => dispatch(proLocList()),
	proLocSave:(values)=>dispatch(proLocSave(values)),
	proLocSaveRes:(values)=>dispatch(proLocSaveRes(values)),
  })
  ProviderLocationForm.propTypes = {
	providerList: PropTypes.func,
	locList: PropTypes.func,
	proLocSave: PropTypes.func,
	proLocList:PropTypes.func,
	proLocSaveRes:PropTypes.func,
  }
  export default connect(mapStateToProps, mapDispatchToProps)(ProviderLocationForm)
