import { combineEpics } from "redux-observable";
import RegistrationEpic from "./Registration/reg.epic";

/** RootEpic
 * @fileOverview Combine all the APIs calls w.r.t action in the redux
 * @author Athul George <athul@trillium-technologies.com>
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @author Cinu James <cinu@trillium-technologies.com>
 */
const rootEpic = combineEpics(
  RegistrationEpic.clinicSaveApi,
  RegistrationEpic.providerSaveApi,
  RegistrationEpic.providerListApi,
  RegistrationEpic.userSaveApi,
  RegistrationEpic.userListApi,
  RegistrationEpic.userUpdateApi,
  RegistrationEpic.locSaveApi,
  RegistrationEpic.locListApi,
  RegistrationEpic.specialityApi,
  RegistrationEpic.apmtlistApi,
  RegistrationEpic.apmtSaveApi,
  RegistrationEpic.proLocSaveApi,
  RegistrationEpic.proLocListApi,
  RegistrationEpic.getproScheduleApi,
  RegistrationEpic.verifyClinicApi,
  RegistrationEpic.fileUpload,
  RegistrationEpic.emrApi,
  RegistrationEpic.mailTriggerApi,
  RegistrationEpic.providerDeleteApi,
  RegistrationEpic.locDeleteApi,
  RegistrationEpic.aptDeleteApi,
  RegistrationEpic.emailPhoneUpdateApi
);
export default rootEpic;
