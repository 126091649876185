import {
  CLINIC_SAVE,
  CLINIC_SAVE_RES,
  PROVIDER_SAVE,
  PROVIDER_SAVE_RES,
  PROVIDER_LIST,
  PROVIDER_LIST_RES,
  SAVE_USER,
  SAVE_USER_RES,
  USER_LIST,
  USER_LIST_RES,
  USER_UPDATE,
  USER_UPDATE_RES,
  LOC_SAVE,
  LOC_SAVE_RES,
  LOC_LIST,
  LOC_LIST_RES,
  SPECIALITY,
  SPECIALITY_RES,
  APMT_LIST,
  APMT_LIST_RES,
  APMT_SAVE,
  APMT_SAVE_RES,
  SAVE_PRO_LOC,
  SAVE_PRO_LOC_RES,
  PRO_LOC_LIST,
  PRO_LOC_LIST_RES,
  GET_PRO_SCHEDULE,
  GET_PRO_SCHEDULE_RES,
  VERIFY_CLINIC,
  VERIFY_CLINIC_RES,
  FILE_UPLOAD,
  FILE_UPLOAD_RES,
  EMR,
  EMR_RES,
  MAIL_TRIGGER,
  PROVIDER_DELETE,
  PROVIDER_DELETE_RES,
  LOC_DELETE,
  LOC_DELETE_RES,
  APT_DELETE,
  APT_DELETE_RES,
  EMAIL_PHONE_UPDATE,
  EMAIL_PHONE_UPDATE_RES,
} from "./reg.types";

/**
 * @fileOverview Manages the action w.r.t types in the redux
 * @author Akhil Francis<akhil@trillium-technologies.com>

 * return(
 type and payload
 * )
 * @example export const actionName = (params) => ({
  type: Type of the action from login.type.js,
  payload: object - contains params,
})
 */

/**
 * clinicSave-Action w.r.t CLINIC_SAVE(type) in the redux
 * @description - Action used to call the clinic save API
 * @param logData
 * @returns {{payload: *, type: string}}
 */
export const clinicSave = (data) => ({
  type: CLINIC_SAVE,
  payload: data,
});
/**
 * clinicSaveResponse - Action w.r.t CLINIC_SAVE_RES(type) in the redux
 * @description - Action used to get the response of clinic save API
 * @param user
 * @returns {{payload: *, type: string}}
 */
export const clinicSaveResponse = (data) => ({
  type: CLINIC_SAVE_RES,
  payload: data,
});

export const providerSave = (data) => ({
  type: PROVIDER_SAVE,
  payload: data,
});

export const providerSaveResponse = (data) => ({
  type: PROVIDER_SAVE_RES,
  payload: data,
});

export const providerList = (data) => ({
  type: PROVIDER_LIST,
  payload: data,
});

export const providerListResponse = (data) => ({
  type: PROVIDER_LIST_RES,
  payload: data,
});

export const userSave = (data) => ({
  type: SAVE_USER,
  payload: data,
});

export const userSaveResponse = (data) => ({
  type: SAVE_USER_RES,
  payload: data,
});

export const userList = (data) => ({
  type: USER_LIST,
  payload: data,
});

export const userListResponse = (data) => ({
  type: USER_LIST_RES,
  payload: data,
});

export const userUpdate = (data) => ({
  type: USER_UPDATE,
  payload: data,
});

export const userUpdateResponse = (data) => ({
  type: USER_UPDATE_RES,
  payload: data,
});

export const locSave = (data) => ({
  type: LOC_SAVE,
  payload: data,
});

export const locSaveResponse = (data) => ({
  type: LOC_SAVE_RES,
  payload: data,
});

export const locList = (data) => ({
  type: LOC_LIST,
  payload: data,
});

export const locListResponse = (data) => ({
  type: LOC_LIST_RES,
  payload: data,
});

export const getSpeciality = (data) => ({
  type: SPECIALITY,
  payload: data,
});

export const specialityResponse = (data) => ({
  type: SPECIALITY_RES,
  payload: data,
});
export const apmtSave = (data) => ({
  type: APMT_SAVE,
  payload: data,
});

export const apmtSaveResponse = (data) => ({
  type: APMT_SAVE_RES,
  payload: data,
});
export const apmtList = (data) => ({
  type: APMT_LIST,
  payload: data,
});

export const apmtListResponse = (data) => ({
  type: APMT_LIST_RES,
  payload: data,
});
export const proLocSave = (data) => ({
  type: SAVE_PRO_LOC,
  payload: data,
});

export const proLocSaveRes = (data) => ({
  type: SAVE_PRO_LOC_RES,
  payload: data,
});

export const proLocList = (data) => ({
  type: PRO_LOC_LIST,
  payload: data,
});

export const proLocListResponse = (data) => ({
  type: PRO_LOC_LIST_RES,
  payload: data,
});

export const getproSchedule = (data) => ({
  type: GET_PRO_SCHEDULE,
  payload: data,
});

export const getproScheduleRes = (data) => ({
  type: GET_PRO_SCHEDULE_RES,
  payload: data,
});
export const verifyClinic = (data) => ({
  type: VERIFY_CLINIC,
  payload: data,
});

export const verifyClinicRes = (data) => ({
  type: VERIFY_CLINIC_RES,
  payload: data,
});

export const fileUpload = (data) => ({
  type: FILE_UPLOAD,
  payload: data,
});

export const fileUploadResponse = (res) => ({
  type: FILE_UPLOAD_RES,
  payload: res,
});
export const getEmr = (data) => ({
  type: EMR,
  payload: data,
});

export const emrResponse = (data) => ({
  type: EMR_RES,
  payload: data,
});
export const mailTrigger = (data) => ({
  type: MAIL_TRIGGER,
  payload: data,
});

export const mailTriggerResponse = (data) => ({
  type: EMR_RES,
  payload: data,
});
export const providerDelete = (data) => ({
  type: PROVIDER_DELETE,
  payload: data,
});

export const providerDeleteResponse = (data) => ({
  type: PROVIDER_DELETE_RES,
  payload: data,
});
export const locDelete = (data) => ({
  type: LOC_DELETE,
  payload: data,
});

export const locDeleteResponse = (data) => ({
  type: LOC_DELETE_RES,
  payload: data,
});
export const aptDelete = (data) => ({
  type: APT_DELETE,
  payload: data,
});

export const aptDeleteResponse = (data) => ({
  type: APT_DELETE_RES,
  payload: data,
});
export const emailPhoneUpdate = (data) => ({
  type: EMAIL_PHONE_UPDATE,
  payload: data,
});

export const emailPhoneUpdateRes = (data) => ({
  type: EMAIL_PHONE_UPDATE_RES,
  payload: data,
});
