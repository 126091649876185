/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = "REQUEST_CANCEL";
export const CLINIC_SAVE = "CLINIC_SAVE";
export const CLINIC_SAVE_RES = "CLINIC_SAVE_RES";
export const PROVIDER_SAVE = "PROVIDER_SAVE";
export const PROVIDER_SAVE_RES = "PROVIDER_SAVE_RES";
export const PROVIDER_LIST = "PROVIDER_LIST";
export const PROVIDER_LIST_RES = "PROVIDER_LIST_RES";
export const SAVE_USER = "SAVE_USER";
export const SAVE_USER_RES = "SAVE_USER_RES";
export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_RES = "USER_UPDATE_RES";
export const USER_LIST = "USER_LIST";
export const USER_LIST_RES = "USER_LIST_RES";
export const LOC_SAVE = "LOC_SAVE";
export const LOC_SAVE_RES = "LOC_SAVE_RES";
export const LOC_LIST = "LOC_LIST";
export const LOC_LIST_RES = "LOC_LIST_RES";
export const SPECIALITY = "SPECIALITY";
export const SPECIALITY_RES = "SPECIALITY_RES";
export const APMT_SAVE = "APMT_SAVE";
export const APMT_SAVE_RES = "APMT_SAVE_RES";
export const APMT_LIST = "APMT_LIST";
export const APMT_LIST_RES = "APMT_LIST_RES";
export const SAVE_PRO_LOC = "SAVE_PRO_LOC";
export const SAVE_PRO_LOC_RES = "SAVE_PRO_LOC_RES";
export const PRO_LOC_LIST = "PRO_LOC_LIST";
export const PRO_LOC_LIST_RES = "PRO_LOC_LIST_RES";
export const GET_PRO_SCHEDULE = "GET_PRO_SCHEDULE";
export const GET_PRO_SCHEDULE_RES = "GET_PRO_SCHEDULE_RES";
export const VERIFY_CLINIC = "VERIFY_CLINIC";
export const VERIFY_CLINIC_RES = "VERIFY_CLINIC_RES";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const FILE_UPLOAD_RES = "FILE_UPLOAD_RES";
export const EMR = "EMR";
export const EMR_RES = "EMR_RES";
export const MAIL_TRIGGER = "MAIL_TRIGGER";
export const MAIL_TRIGGER_RES = "MAIL_TRIGGER_RES";
export const PROVIDER_DELETE = "PROVIDER_DELETE";
export const PROVIDER_DELETE_RES = "PROVIDER_DELETE_RES";
export const LOC_DELETE = "LOC_DELETE";
export const LOC_DELETE_RES = "LOC_DELETE_RES";
export const APT_DELETE = "APT_DELETE";
export const APT_DELETE_RES = "APT_DELETE_RES";
export const EMAIL_PHONE_UPDATE = "EMAIL_PHONE_UPDATE";
export const EMAIL_PHONE_UPDATE_RES = "EMAIL_PHONE_UPDATE_RES";
