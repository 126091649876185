import React from "react";
import "../../GlobalStyles.css";

function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="footer_content">
          © 2023 Trillium Health. All Rights Reserved.
        </div>
        <div className="footer_item">
          <div className="footer_content">
            <a
              className="footer_link"
              href="https://trillium.health/terms-of-usage.html"
              target="_blank"
            >
              Terms of use
            </a>
          </div>
          <div className="footer_content">
            <a
              className="footer_link"
              href="https://trillium.health/terms-of-usage.html"
              target="_blank"
            >
              Privacy policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
