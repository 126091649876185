import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import store from "./Redux/store";
import App from "./App";

const theme = createTheme({
  typography: {
    fontFamily: `Inter, sans-serif`,
  },
  palette: {
    primary: {
      // main: '#3cd970',
      // main: '#41dd75',
      main: "#1ACE7F",
      contrastText: "#ffff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#ffff",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
