import React from "react";
import "./style.css";

function MobileView(props) {
  return (
    <div className="parentDiv">
      <div>
        <p>
          Looks like you are using a mobile phone. Please use your desktop to
          complete the registration
        </p>
      </div>
    </div>
  );
}
export default MobileView;
