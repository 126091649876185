import React, { useEffect, useState } from 'react'
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

/**
 * @fileOverview Manages Reusable Time Picker.
 * @component
 * @author Cinu James  <cinu@trillium-technologies.com>
 * @param {props} value from the parent component
 * @example
 * return(
 * <TimePicker/>
 * )
 */
function TimePicker(props) {
  const [value, setValues] = useState('')

  /**
   * rendering the props value
   */
  useEffect(() => {
    setValues(props.value)
  }, [props.value])

  /**
   * onChange of timepicker
   */
  const handleDateChange = (event) => {
    setValues(event)
    if (props.onChange) {
      const target = { type: 'time', checked: null, value: event }
      props.onChange({ target })
    }
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        fullWidth
        id="time-picker"
        label={props.placeholder ? props.placeholder : ''}
        value={value}
        minDateMessage="Time should be less than start time"
        {...props}
        // label="Time picker"
        invalidDateMessage={props.invalidDateMessage}
        InputLabelProps={{
          shrink: true,
        }}
        // value={selectedDate}
        onChange={handleDateChange}
        keyboardIcon={<AccessTimeIcon />}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
        variant="outlined"
      />
    </MuiPickersUtilsProvider>
  )
}

export default TimePicker
