import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  providerSave,
  providerList,
  providerSaveResponse,
  getSpeciality,
  providerDelete,
} from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Input from "components/Input";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import PhoneFormat from "components/PhoneFormat";
import CustomButton from "components/CustomButton/CustomButton";
import Dropdown from "components/Dropdown";
import toast from "react-hot-toast";
import { useForm } from "Hooks/validator";
import { isValidNpi } from "HelperFunctions/npi";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteDialog from "./DeleteDialog";

const useStyles = makeStyles((theme) => ({
  root: {},
  accordion: {
    margin: "16px 0",
    borderRadius: "8px",
    boxShadow: "none",
    border: "1px solid var(--line-grey)",
    "&:before": {
      display: "none",
    },
  },
  accordionContent: {
    padding: "16px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function ProviderForm(props) {
  const classes = useStyles();
  const [speciality, setSpeciality] = useState([]);
  const [npiValid, setnpiValid] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [errors, setErrors] = useState({
    password: "",
  });
  const defaultValues = {
    active: 1,
    roleId: 4,
    clinicId: "",
    credential: "",
    email: "",
    firstName: "",
    id: 0,
    lastName: "",
    middleName: "",
    npi: null,
    password: "",
    phone: "",
    speciality: "",
    taxonomy: localStorage.getItem("clinicData")
      ? JSON.parse(localStorage.getItem("clinicData")).taxonomy
      : "",
    userId: 0,
    userName: "",
    location: "",
  };
  const customErrorAttribute = {
    className: "has-error",
    "another-attr": "look-at-me",
    error: true,
    helperText: "This field is required",
  };

  const { values, setValues, isValid, useInput } = useForm(
    defaultValues,
    customErrorAttribute
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValid) {
      if (
        (values.password && values.userName) ||
        (values.password === "" && values.userName === "")
      ) {
        props.providerSave({
          active: values.active ? values.active : "",
          roleId: 4,
          clinicId: localStorage.getItem("cid"),
          credential: values.credential ? values.credential : "",
          email: values.email ? values.email : "",
          firstName: values.firstName ? values.firstName : "",
          id: values.id ? values.id : 0,
          lastName: values.lastName ? values.lastName : "",
          middleName: values.middleName ? values.middleName : "",
          npi: values.npi ? values.npi : "",
          password: values.password ? values.password : "",
          phone: values.phone ? values.phone : "",
          speciality: speciality.find((x) => x.value === values.taxonomy).name,
          taxonomy: values.taxonomy ? values.taxonomy : "",
          userId: values.userId ? values.userId : 0,
          userName: values.userName ? values.userName : "",
          location: "",
        });
      } else {
        if (values.password === "") {
          setErrors({
            ...errors,
            password: "Please enter your password to continue",
          });
        }
      }
    }
  };
  useEffect(() => {
    if (props.regData.speciality && props.regData.speciality.response) {
      const response = props.regData.speciality.response;
      if (response.responseCode === 0) {
        let specaility = [];
        response.data &&
          response.data.forEach((x) => {
            specaility.push({
              name: x.value,
              value: x.code,
            });
          });
        setSpeciality(specaility);
      }
    }
  }, [props.regData.speciality]);

  useEffect(() => {
    props.getSpeciality();
  }, []);

  useEffect(() => {
    if (
      props.regData.providerResponse &&
      props.regData.providerResponse.response
    ) {
      const response = props.regData.providerResponse.response;
      if (response.responseCode === 0) {
        props.handleClose();
        if (values.id === 0) {
          toast.success("Provider added successfully");
        } else {
          toast.success("Provider updated successfully");
        }
        props.providerSaveResponse(null);
        props.providerList();
      }
      if (response.responseCode === 160) {
        toast.error("Email already exist");
      }
    }
  }, [props.regData.providerResponse]);

  useEffect(() => {
    if (props.editValue) {
      if (props.editValue.password) {
        setPasswordStatus(true);
      }
      setValues(props.editValue);
    }
  }, [props.editValue]);

  const validateNpi = (e) => {
    if (isValidNpi(e.target.value)) {
      setnpiValid(false);
    } else {
      setnpiValid(true);
    }
  };

  const handleNpi = (e) => {
    setValues({ ...values, npi: e.target.value });
  };

  // const credentialOptions = [
  // 	{ value: 1, name: 'Dr.' },
  // 	{ value: 2, name: 'Nurse' },
  // ];

  useEffect(() => {
    if (
      props.regData.providerDeleteResponse &&
      props.regData.providerDeleteResponse.response
    ) {
      const response = props.regData.providerDeleteResponse.response;
      if (response.responseCode === 0) {
        setDeleteDialog(false);
      }
    }
  }, [props.regData.providerDeleteResponse]);
  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={5} justifyContent="space-between">
          {/* <Grid item xs={12} sm={4}>
						<Dropdown
							required
							id={'credentials'}
							tabIndex={3}
							options={credentialOptions}
							label='Credentials'
						/>
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Input
              required
              id={"firstName"}
              tabIndex={1}
              type="text"
              label="First Name"
              {...useInput("firstName", {
                isRequired: {
                  value: true,
                  message: "Enter a valid name",
                },
                isLength: {
                  value: { max: 100 },
                  message: "Max Length is 100",
                },
                matches: {
                  value: /^[a-zA-Z][a-zA-Z -]+$/,
                  message: "Enter the valid name",
                },
              })}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
						<Input
							// required
							id={'middleName'}
							tabIndex={2}
							type='text'
							label='Middle Name'
							{...useInput('middleName', {
								// isRequired: {
								//   value: true,
								//   message: 'Enter a valid name',
								// },
								isLength: {
									value: { max: 100 },
									message: 'Max Length is 15',
								},
								matches: {
									value: /^[a-zA-Z][a-zA-Z -]+$/,
									message: 'Enter the valid name',
								},
							})}
						/>
					</Grid> */}
          <Grid item xs={12} sm={4}>
            <Input
              required
              id={"lastName"}
              tabIndex={2}
              type="text"
              label="Last Name"
              {...useInput("lastName", {
                isRequired: {
                  value: true,
                  message: "Enter a valid name",
                },
                isLength: {
                  value: { max: 100 },
                  message: "Max Length is 100",
                },
                matches: {
                  value: /^[a-zA-Z][a-zA-Z -]+$/,
                  message: "Enter the valid name",
                },
              })}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              required
              id={"credential"}
              tabIndex={8}
              type="text"
              label="Credential"
              {...useInput("credential", {
                isRequired: {
                  value: true,
                  message: "Enter a valid Credential",
                },
              })}
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Dropdown
              required
              id={"rodfgdfgle"}
              tabIndex={3}
              options={speciality}
              label="Speciality"
              {...useInput("taxonomy", {
                isRequired: {
                  value: true,
                  message: "Please select a speciality",
                },
              })}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Input
              required
              InputProps={{
                autocomplete: "new-password",
              }}
              name="npi"
              type="text"
              label="NPI"
              onBlur={validateNpi}
              error={npiValid}
              helperText={npiValid ? "Enter a valid NPI" : ""}
              onChange={handleNpi}
              value={values.npi}
            />
          </Grid>
        </Grid>

        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="body1">
              Other Information (Optional)
            </Typography>
          </AccordionSummary>
          <div className={classes.accordionContent}>
            <Grid container spacing={5} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <Input
                  // required
                  id={"email"}
                  tabIndex={15}
                  type="email"
                  label="Email"
                  {...useInput("email", {
                    matches: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Enter a valid email",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PhoneFormat
                  id={"phone"}
                  tabIndex={13}
                  type="text"
                  label="Phone"
                  placeholder="(555) 555-5555"
                  {...useInput("phone", {
                    matches: {
                      value: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                      message: "Enter a valid phone number",
                    },
                  })}
                />
              </Grid>
            </Grid>
            <Grid container spacing={5} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <Input
                  id={"userName"}
                  tabIndex={1}
                  type="text"
                  label="User Name"
                  //   onTab={userNameCheck}
                  //   error={userNameErrorStatus}
                  //   helperText={
                  //     userNameError === 1
                  //       ? 'Enter a valid Username'
                  //       : userNameError === 4
                  //       ? 'That Username is taken.Try another'
                  //       : ''
                  //   }
                  {...useInput("userName", {
                    // isRequired: {
                    // 	value: true,
                    // 	message: 'Enter a valid Username',
                    // },
                    isLength: {
                      value: { min: 0, max: 200 },
                      message: "Username must be between 6 and 15 characters",
                    },
                  })}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  id={"password"}
                  tabIndex={2}
                  type="password"
                  disabled={passwordStatus}
                  label="Password"
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                  onBlur={() => setErrors({ ...errors, password: "" })}
                  value={values.password}
                  // {...useInput("password", {
                  //   isRequired: {
                  //     value: true,
                  //     message: 'Enter a valid Password',
                  //   },
                  //   isLength: {
                  //     value: { min: 6, max: 15 },
                  //     message: "Password must be between 6 and 15 characters",
                  //   },
                  //   matches: {
                  //   	value:
                  //   		/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{3,100}$/,
                  //   	message:
                  //   		'Enter a valid password with at least 1 digit, 1 letter and 1 character',
                  //   },
                  // })}
                />
              </Grid>
            </Grid>
          </div>
        </Accordion>

        <div className="form__btnWrapper">
          {values.userId ? (
            <CustomButton
              name="Delete"
              onClick={() => setDeleteDialog(true)}
              default
            />
          ) : null}
          <CustomButton name="Cancel" onClick={props.handleClose} default />
          <CustomButton name="Submit" type="submit" />
        </div>
      </form>
      <DeleteDialog
        deleteDialog={deleteDialog}
        handleCloseDelete={() => setDeleteDialog(false)}
        triggerDelete={() => props.providerDelete(values.userId)}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  providerSave: (values) => dispatch(providerSave(values)),
  providerSaveResponse: (values) => dispatch(providerSaveResponse(values)),
  providerList: () => dispatch(providerList()),
  getSpeciality: () => dispatch(getSpeciality()),
  providerDelete: (values) => dispatch(providerDelete(values)),
});
ProviderForm.propTypes = {
  providerSave: PropTypes.func,
  providerList: PropTypes.func,
  providerSaveResponse: PropTypes.func,
  getSpeciality: PropTypes.func,
  providerDelete: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProviderForm);
