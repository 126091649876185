import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { verifyClinic, emailPhoneUpdate } from "Redux/Registration/reg.actions";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Input from "components/Input";
import CustomButton from "components/CustomButton/CustomButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import TickIcon from "../../../assets/tick-icon.svg";
import PhoneFormat from "components/PhoneFormat";
import "../../../GlobalStyles.css";
import "./style.css";
import { toast } from "react-hot-toast";

const useStyles = makeStyles({
  dialogButton: {
    display: "block",
    padding: "8px 24px",
  },
});

function VerifyUser(props) {
  const [open, setOpen] = useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [changeField, setChangeField] = useState("");
  const [params, setParams] = React.useState({
    email: "",
    phone: "",
  });
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [values, setValues] = useState({
    email: "",
    phone: "",
  });

  const handleClickEmail = (event) => {
    setChangeField("email");
    setOpen(true);
  };
  const handleClickPhone = (event) => {
    setChangeField("phone");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function parseURLParams(url) {
    var queryStart = url.indexOf("?") + 1,
      queryEnd = url.indexOf("#") + 1 || url.length + 1,
      query = url.slice(queryStart, queryEnd - 1),
      pairs = query.replace(/\+/g, " ").split("&"),
      parms = {},
      i,
      n,
      v,
      nv;

    if (query === url || query === "") return;

    for (i = 0; i < pairs.length; i++) {
      nv = pairs[i].split("=", 2);
      n = decodeURIComponent(nv[0]);
      v = decodeURIComponent(nv[1]);

      if (!parms.hasOwnProperty(n)) parms[n] = [];
      parms[n].push(nv.length === 2 ? v : null);
    }
    return parms;
  }

  useEffect(() => {
    let p = parseURLParams(window.location.href);
    if (p) {
      setParams({
        email: p.email[0],
        phone: retrunNumber(p.mobile[0]),
      });
      localStorage.setItem("accessToken", p.token[0]);
      setForm({
        username: p.username[0],
        password: "",
      });
    } else {
      let data = JSON.parse(localStorage.getItem("clinicData"));
      if (data) {
        setParams({
          email: data.email,
          phone: data.contactPhone,
        });
      }
    }
  }, []);

  const verifyFn = (e) => {
    e.preventDefault();
    if (form.username && form.password) {
      props.verifyClinic({
        userName: form.username,
        password: form.password,
      });
    }
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };
  useEffect(() => {
    if (
      props.regData.verifyClinicResponse &&
      props.regData.verifyClinicResponse.response
    ) {
      const response = props.regData.verifyClinicResponse.response;
      if (response.responseCode === 0) {
        setDialog(true);
        setTimeout(() => {
          window.location.href = "/upload";
        }, 2000);
      }
    }
  }, [props.regData.verifyClinicResponse]);

  const retrunNumber = (e) => {
    if (e) {
      let s = e.split("");
      return `(${s[0] + s[1] + s[2]})-${s[3] + s[4] + s[5]}-${
        s[6] + s[7] + s[8] + s[9]
      }`;
    }
  };

  // const removeMask = (x) => {
  //   let a = x.split("");
  //   return (
  //     a[1] + a[2] + a[3] + a[6] + a[7] + a[8] + a[10] + a[11] + a[12] + a[13]
  //   );
  // };

  const handleEmailChange = (e) => {
    e.preventDefault();
    props.emailPhoneUpdate({
      email: values.email,
      contactPhone: "",
    });
  };
  const handlePhoneChange = (e) => {
    e.preventDefault();
    props.emailPhoneUpdate({
      email: "",
      contactPhone: values.phone,
    });
  };
  useEffect(() => {
    if (
      props.regData.emailPhoneUpdate &&
      props.regData.emailPhoneUpdate.response
    ) {
      const response = props.regData.emailPhoneUpdate.response;
      if (response.responseCode === 0) {
        if (changeField === "email") {
          toast.success("Email  sent successfully");
          setParams({ ...params, email: values.email });
        }
        if (changeField === "phone") {
          toast.success("Password  sent successfully");
          setParams({ ...params, phone: values.phone });
        }
        setOpen(false);
        setChangeField("");
      }
    }
  }, [props.regData.emailPhoneUpdate]);

  const resendEmail = () => {
    setChangeField("resend");
    props.emailPhoneUpdate({
      email: params.email,
      contactPhone: "",
    });
  };

  const resendPhone = () => {
    setChangeField("resend");
    props.emailPhoneUpdate({
      email: "",
      contactPhone: params.phone,
    });
  };
  return (
    <div className="verifyUser__stepper__container">
      <p className="verifyUser_heading_tag">
        Verify your Email and Phone Number
      </p>
      <div className="p-sm">
        <form onSubmit={verifyFn}>
          <Grid container spacing={3} direction="column">
            <Grid item xs>
              <Typography variant="body1" gutterBottom>
                Enter the Username sent to{" "}
                <span className="fw-500">{params && params.email}</span>{" "}
                <span className="link" onClick={handleClickEmail}>
                  Change
                </span>
              </Typography>

              <Input
                value={form.username}
                required
                type="text"
                label="Username"
                onChange={(e) => setForm({ ...form, username: e.target.value })}
              />
              <Typography variant="body2" gutterBottom>
                Didnt receive the username ?{" "}
                <span className="link" onClick={resendEmail}>
                  Resend
                </span>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="body1" gutterBottom>
                Enter the Password sent to{" "}
                <span className="fw-500">
                  {"+1 "}
                  {params && params.phone}
                </span>{" "}
                <span className="link" onClick={handleClickPhone}>
                  Change
                </span>
              </Typography>

              <Input
                value={form.password}
                required
                type="password"
                label="Password"
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
              <Typography variant="body2" gutterBottom>
                Didnt receive the code ?{" "}
                <span className="link" onClick={resendPhone}>
                  Resend
                </span>
              </Typography>
            </Grid>
          </Grid>

          <div className="width-100 center right gap-xs">
            <CustomButton name={"Next"} type="submit" />
          </div>
        </form>
      </div>
      <Dialog
        open={dialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* ************* Verfication Successful Screen ************* */}
        <div className="p-sm center center-column dialog">
          <img src={TickIcon} alt="" />
          <DialogTitle
            id="alert-dialog-title"
            className="text-center"
            style={{ maxWidth: "300px" }}
            disableTypography
          >
            <Typography variant="h6" gutterBottom>
              {"Your Username and Password has been sucessfully verified"}
            </Typography>
          </DialogTitle>
        </div>
      </Dialog>
      <Dialog open={open} onClose={handleClose}>
        {changeField === "email" && (
          <form onSubmit={handleEmailChange}>
            <div className="popover_main_div">
              <p className="email_header_verify">Change Email</p>
              <Input
                size="small"
                required
                type="text"
                label="Email"
                value={values.email}
                onChange={(e) =>
                  setValues({ ...values, email: e.target.value })
                }
              />
              <div className="popover_main_btn">
                <CustomButton type="submit" name={"Send"} />
              </div>
            </div>
          </form>
        )}
        {changeField === "phone" && (
          <form onSubmit={handlePhoneChange}>
            <div className="popover_main_div">
              <p className="email_header_verify">Change Phone</p>
              <PhoneFormat
                size="small"
                value={values.phone}
                required
                type="text"
                label="Phone"
                onChange={(e) =>
                  setValues({ ...values, phone: e.target.value })
                }
              />
              <div className="popover_main_btn">
                <CustomButton name={"Send"} type="submit" />
              </div>
            </div>
          </form>
        )}
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  regData: state.reg,
});

const mapDispatchToProps = (dispatch) => ({
  verifyClinic: (values) => dispatch(verifyClinic(values)),
  emailPhoneUpdate: (values) => dispatch(emailPhoneUpdate(values)),
});
VerifyUser.propTypes = {
  verifyClinic: PropTypes.func,
  emailPhoneUpdate: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyUser);
