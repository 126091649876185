import React, { useState, useEffect } from "react";
import HorizonalStepper from "./components/Stepper/HorizontalStepper";
import { Toaster } from "react-hot-toast";
// import TrilliumLogo from "./assets/trillium-logo.svg";
import Header from "components/Header";
import Footer from "components/Footer";
import "./GlobalStyles.css";

function App() {
  // const [scrolled, setScrolled] = useState(false);

  // useEffect(() => {
  //   window.onscroll = function () {
  //     if (window.scrollY > 20) {
  //       setScrolled(true);
  //     } else {
  //       setScrolled(false);
  //     }
  //   };
  // }, []);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <Header />
      <HorizonalStepper />
      <Footer />
    </div>
  );
}

export default App;
